import React from "react";
import Header from "../../components/header/Header";
import HassleSection from "../../components/hassle";
import Weare from "../../components/weareetnowe/Weare";
import Innovativ from "../../components/Innovative/Innovativ";
import Revolution from "../../components/Revolution/Revolution";
import HowItWorks from "../../components/HowItWorks/HowItWorks";
import Map from "../../components/map";
import Features from "../../components/Features/Features";
import Contactus from "../../components/ContactUs/Contactus";
import Footer from "../../components/Footer/Footer";
function LadningPage() {
  return (
    <div>
      <Header />
      <HassleSection />
      <Weare />
      <Innovativ />
      <Map />
      <Revolution />
      <HowItWorks />
      <Features />
      <Contactus />
      <Footer />
    </div>
  );
}

export default LadningPage;
