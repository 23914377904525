import React from "react";
import Map from "./Map";
import "./style.css";

function TotalContainer() {
  return (
    <div
      style={{
        marginTop: "15rem",
      }}
      className="mobileviewofmap"
    >
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <h1
          style={{
            fontSize: "3.2rem",
            color: "#393939",
            fontFamily: "SF Pro Display",
            fontWeight: "bold",
          }}
        >
          JUST CHECK YOUR MAP
        </h1>
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <p
          style={{
            fontSize: "1.6rem",
            color: "#94cb64",
            fontFamily: "SF Pro Display",
            fontWeight: "bold",
            marginTop: "-1rem",
          }}
        >
          YOU MIGHT FIND US IN YOUR AREA
        </p>
      </div>
      <Map />
    </div>
  );
}

export default TotalContainer;
