import React from "react";
import "./style.css";
function Weare() {
  return (
    <div id="aboutus" className="maindiv_of_weare">
      <h1 className="weareetn">WE ARE ETNOWE</h1>
      <div className="text_div_of_weare">
        <p>
          ETNOWE is a revolutionary and innovative marketplace for African and
          Caribbean grocery stores in the U.S., facilitating seamless shopping,
          quick delivery, and rapid pickup. Our primary focus is to bridge the
          socioeconomic gap by providing delivery capabilities for all the
          ethnic grocery stores located in the U.S.
        </p>
        <p>
          The demand for grocery shopping and delivery services has increased
          dramatically in the last few years, even more so because of the
          COVID-19 Pandemic. The rising preference of consumers towards online
          grocery shopping and demand for user-friendly interfaces and
          additional discounts have been fueling demand in the market.
        </p>
        <p>
          Due to the increase in demand for online grocery shopping and delivery
          services, many stores are currently incorporating online shopping and
          delivery services as a key part of their business, maximizing
          innovative technology to reach out to more prospective clients online,
          boosting their chances of making more sales. As such, ETNOWE has
          pioneered a robust virtual marketplace for customers and prospective
          shoppers to shop in one location. ETNOWE will help you serve your
          customers better while you experience better sales and swift
          transactions.
        </p>
      </div>
    </div>
  );
}

export default Weare;
