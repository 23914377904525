import React from "react";
import "./style.css";
import { Row, Col } from "antd";
import download from "../../assets/svg/download.svg";
import cart from "../../assets/svg/shop.svg";

import secure from "../../assets/svg/secure.svg";

import pin from "../../assets/svg/pin.svg";
import { Container } from "react-bootstrap";

function HowItWorks() {
  return (
    <div className="maindivofhowitworks">
      <h1 className="h1ofhowitworks">How it works</h1>

      <Container>
        <Row justify="space-between">
          <Col lg={5} md={10} xs={10}>
            <div className="jutsifycenterdivofcolumns">
              <div className="backg_download_button">
                <img className="donwloadLogo" src={download} />
              </div>
            </div>
            <p className="textofcoloumnhowitworks">
              Download ETNOWE from the App Store
            </p>
          </Col>
          <Col lg={5} md={10} xs={10}>
            <div className="jutsifycenterdivofcolumns">
              <div className="backg_download_button">
                {" "}
                <img className="donwloadLogo" src={cart} />
              </div>
            </div>
            <p className="textofcoloumnhowitworks">
              Shop from stores near you and at any time of the day{" "}
            </p>
          </Col>
          <Col className="margintopformobile" lg={5} md={10} xs={10}>
            <div className="jutsifycenterdivofcolumns">
              <div className="backg_download_button">
                {" "}
                <img className="donwloadLogo" src={secure} />
              </div>
            </div>
            <p className="textofcoloumnhowitworks">Secure checkout </p>
          </Col>
          <Col className="margintopformobile" lg={5} md={10} xs={10}>
            <div className="jutsifycenterdivofcolumns">
              <div className="backg_download_button">
                {" "}
                <img className="donwloadLogo" src={pin} />
              </div>
            </div>
            <p className="textofcoloumnhowitworks">
              Get your delivery same day or it pick
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HowItWorks;
