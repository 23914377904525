import React from "react";
import "./style.css";
import logo from "../../assets/imgs/LogoWords.png";
import text from "../../assets/imgs/browntext.png";
import { Navbar, Container, Nav } from "react-bootstrap";
function Header() {
  return (
    <div id="tophead">
      <Navbar collapseOnSelect expand="lg">
        <Container>
          <Navbar.Brand href="/">
            {" "}
            <div>
              <img className="logoc" src={logo} alt="logo" />
              <img className="logotexttt" src={text} alt="logo" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav className="a_links">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="#aboutus">About Us</Nav.Link>
              {/* <Nav.Link href="##">Appointment</Nav.Link> */}
              <Nav.Link href="#contactus">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
