import React from "react";
import "./style.css";
import boy from "../../assets/imgs/boy.png";
import gobil from "../../assets/imgs/gobil.png";
import gobir from "../../assets/imgs/gobir.png";
import shimla from "../../assets/imgs/shimla.png";
import tomato from "../../assets/imgs/tomato.png";
import grocery from "../../assets/imgs/grocery.png";
import google from "../../assets/svg/google.svg";
import appstore from "../../assets/svg/appstore.svg";

import { DownOutlined } from "@ant-design/icons";

function HassleSection() {
  return (
    <div className="maindiv">
      <div className="hasslesection">
        <img className="boy_pic" src={boy} alt="boy" />
        <img className="gobil1" src={gobil} alt="vegetables" />
        <img className="gobil3" src={gobir} alt="vegetables" />
        <img className="gobil2" src={shimla} alt="vegetables" />
        <img className="gobil4" src={tomato} alt="vegetables" />
        <img className="gobil5" src={grocery} alt="vegetables" />

        <div className="hasle_div_text">
          <h1 className="headingggggg">Hassle-Free</h1>
          <h1 className="headingggggg">Grocery Shopping</h1>
          <h1 className="headingggggg">at your fingertips.</h1>
        </div>
        <div className="hasle_div_bottom_text">
          <p className="bottom_text_with_button">
            An innovative solution to shop
          </p>
          <p className="bottom_text_with_button">
            African & Caribbean groceries from
          </p>
          <p className="bottom_text_with_button">
            from your phone to your doorstep.
          </p>
          {/* <a href="#download" style={{ textDecoration: "none" }}>
            <button className="download_button">Download</button>
          </a> */}
          <div className="exchangedownload">
            <a
              href="https://apps.apple.com/us/app/etnowe-grocery-deliveries/id1531814543"
              target="_blank"
            >
              <img style={{ height: "6rem" }} src={appstore} alt="appstore" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.teyematics.etnowe"
              target="_blank"
            >
              <img style={{ height: "6rem" }} src={google} alt="appstore" />
            </a>
          </div>
          <p className="bottom_text_with_button1">
            Store Owner?{" "}
            <a
              href="https://www.admin.etnowe.com/"
              target="_blank"
              className="bottom_text_with_button2"
            >
              Click here
            </a>
          </p>
        </div>
        <div className="brwoncircle" />
        <div className="greencircle" />
        <div className="seagreencircle" />
        <div className="background_of_icons" />
        <DownOutlined className="iconss" />
      </div>
    </div>
  );
}

export default HassleSection;
