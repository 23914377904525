import React from "react";
import "./style.css";
import { Row, Col } from "antd";
function Innovativ() {
  return (
    <div className="main_div_Innovative">
      <div className="image_div">
        <div className="image_above">
          <div className="back_green_inn">
            <div className="inside_of_innovative_green">
              {" "}
              <p>An innovative solution</p>
            </div>
          </div>
          <div className="back_green_inn">
            <h1 className="toshop_african">
              to shop African & Caribbean groceries
            </h1>
          </div>
          <div className="back_green_inn">
            <p className="toshop_african1">from your phone to your doorsteps</p>
          </div>

          {/* stats */}

          <Row className="mobileviewofrowininnovative" justify="space-between">
            <Col lg={5} md={5} xs={20}>
              <div className="columnsofrowininnovative">
                <div className="statsofinnovative">1M +</div>
              </div>
              <div className="textofrwocolumninnovative">
                Customers visit ETNOWE every month to shop grocery online.
              </div>
            </Col>
            <Col lg={5} md={5} xs={20}>
              <div className="columnsofrowininnovative">
                <div className="statsofinnovative">92%</div>
              </div>
              <div className="textofrwocolumninnovative">
                Satisfaction rate comes from our awesome customers.
              </div>
            </Col>{" "}
            <Col lg={5} md={5} xs={20}>
              <div className="columnsofrowininnovative">
                <div className="statsofinnovative">4.9</div>
              </div>
              <div className="textofrwocolumninnovative">
                Average customer ratings we have got on all our platforms.
              </div>
            </Col>
            <Col lg={5} md={5} xs={20}>
              <div className="columnsofrowininnovative">
                <div className="statsofinnovative">1329</div>
              </div>
              <div className="textofrwocolumninnovative">
                Average number of orders shipped every hour from our warehouse.
              </div>
            </Col>{" "}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Innovativ;
