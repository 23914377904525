import React from "react";
import "./style.css";
import logo from "../../assets/imgs/LogoWords.png";
import text from "../../assets/imgs/browntext.png";
import fb from "../../assets/svg/fb.svg";
import inst from "../../assets/svg/inst.svg";
import twit from "../../assets/svg/twit.svg";
import you from "../../assets/svg/you.svg";

function Footer() {
  return (
    <div className="fotterr_1">
      <div className="logooffooter">
        <a href="#tophead">
          <img className="logoc" src={logo} alt="logo" />
          <img className="logotexttt" src={text} alt="logo" />
        </a>

        <div className="text_under_logo">
          <p>Let's save you a trip, you can now get your fresh</p>
          <p>groceries from your phone to your doorstep</p>
        </div>

        <div className="iconsClass">
          <a href="https://www.facebook.com/etnowe" target="_blank">
            <img src={fb} alt={"icon"} />
          </a>
          <a
            href="https://www.instagram.com/etnowe_deliveries/"
            target="_blank"
          >
            <img src={inst} alt={"icon"} />
          </a>
          <a href="https://twitter.com/etnowe" target="_blank">
            <img src={twit} alt={"icon"} />
          </a>
          <a
            href="https://www.youtube.com/channel/UC34-rKedCVyIO2km1U5hUsA"
            target="_blank"
          >
            <img src={you} alt={"icon"} />
          </a>
        </div>

        <div className="pageLinksatags">
          <div className="mobileview">
            {/* <a href="##">Shop</a> */}
            {/* <a href="##">Products</a> */}
            <a href="#aboutus">About Us</a>
            <a href="#contactus">Contact</a>
          </div>
        </div>

        <hr className="horizontalline" />
        <div className="bottommenuicons">
          <h2 className="mainButton">Support</h2>
          <div className="bottom_a_links">
            <a href="#contactus">Contact</a>
            <a href="https://privacy.etnowe.com/" target="_blank">
              Privacy Policy
            </a>
            <a
              href="https://etnowecustomers.zendesk.com/hc/en-us/sections/360013580352-FAQ"
              target="_blank"
            >
              FAQ
            </a>
            {/* <a href="##">Feedback</a> */}
          </div>
          <p className="copyright">
            Copyright © 2022 ETNOWE. All Right Reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
