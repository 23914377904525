import React from "react";
import "./style.css";
import { MessageOutlined } from "@ant-design/icons";
function Contactus() {
  return (
    <div id="contactus">
      <h1 className="contactus">Contact us</h1>
      <div className="higherbuttonschatdiv">
        <div className="chat_make_button">
          {" "}
          <MessageOutlined style={{ color: "#fff", fontSize: "2.4rem" }} />
          <p className="p_tag_contactus">Chat Now</p>
        </div>
        <p className="email_info">hello@etnowe.com</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
          marginBottom: "-4rem",
        }}
      >
        <div
          class="fb-share-button"
          data-href="https://www.etnowe.com/"
          data-layout="button_count"
          data-size="large"
        >
          <a
            target="_blank"
            href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.etnowe.com%2F&amp;src=sdkpreparse"
            class="fb-xfbml-parse-ignore"
          >
            Share
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contactus;
