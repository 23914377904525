import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { AimOutlined } from "@ant-design/icons";
import { Form, Input, Button } from "antd";
import "./style.css";

const containerStyle = {
  width: "100vw",
  height: "500px",
};

const center = {
  lat: 34.047478701055645,
  lng: -118.25357486065266,
};

function Search() {
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="upperdiv">
      <div className="search_component">
        {" "}
        <AimOutlined style={{ fontSize: "2.4rem", color: "#087781" }} />
        <Form
          style={{ display: "flex", marginTop: "2.1rem" }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item>
            <Input
              style={{ border: "solid white 1px" }}
              placeholder="Type Location Here"
            />
          </Form.Item>

          <Form.Item>
            <Button
              style={{
                border: "solid white 1px",
                fontFamily: "SF Pro Display",
                color: "#087781",
                fontSize: "1.6rem",
              }}
              htmlType="submit"
            >
              Search
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

function MyComponent() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDP7ffBM4Vl0HKBD0i9Kw0V-3XFAdHi00c",
  });

  const defaultMapOptions = {
    fullscreenControl: false,
    zoomControl: false,
  };

  return isLoaded ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "98.79vw",
      }}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        options={defaultMapOptions}
      >
        <Search />
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
