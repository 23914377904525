import React from "react";
import "./style.css";
import { Row, Col } from "antd";
import google from "../../assets/svg/google.svg";
import appstore from "../../assets/svg/appstore.svg";
import mobiles from "../../assets/imgs/mobiles.png";

function Revolution() {
  return (
    <div id="download" className="maindiv_of_revloutiun">
      <h1 className="headiMain_div_of">THE REVOLUTIONARY SOLUTION FOR</h1>
      <h1 className="headiMain_div_of1">AFRICAN AND CARIBBEAN GROCERY</h1>
      <h1 className="headiMain_div_of">DELIVERY IS HERE</h1>

      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col lg={12} sm={20}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="seagren_box_back">
              <div style={{ marginTop: "10rem" }}>
                <h1 className="textext">Download now and</h1>
                <h1 className="textext">enjoy stress free</h1>

                <h1 className="textext">grocery shopping</h1>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <a
                    href="https://apps.apple.com/us/app/etnowe-grocery-deliveries/id1531814543"
                    target="_blank"
                  >
                    <img
                      className="logostodownloadapp"
                      src={appstore}
                      alt={"google"}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.teyematics.etnowe"
                    target="_blank"
                  >
                    <img
                      className="logostodownloadapp"
                      src={google}
                      alt={"google"}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={12} sm={20}>
          {" "}
          <img className="mobileveiwsofimage" src={mobiles} alt={"google"} />
        </Col>
      </Row>

      <Row style={{ marginTop: "10rem" }} justify="center">
        <div className="own_a_atore">
          <h1 className="doe_po_p">DO YOU OWN A STORE?</h1>
          <a href="https://www.admin.etnowe.com" target="_blank">
            <button className="bitttton" style={{ marginTop: "1rem" }}>
              Click Here
            </button>
          </a>
        </div>
      </Row>
    </div>
  );
}

export default Revolution;
