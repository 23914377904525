import React from "react";
import { Row, Col } from "antd";
import "./style.css";

import imamobile from "../../assets/imgs/mobile1.png";
import secure from "../../assets/svg/secure.svg";

function Features() {
  return (
    <div className="main_of_features">
      <h1 className="h1_of_features">
        Features of the <span className="span_h1_heading">ETNOWE App</span>
      </h1>

      <Row justify={"center"}>
        <Col lg={8}>
          <div className="float_right_div">
            {/* points */}
            <div className="display_flex_div">
              <div className="iconmadebyghias">
                {" "}
                <img className="secure_icon" src={secure} alt={"secureicon"} />
              </div>
              <div className="text_ofFeaturs">
                <div className="lineHeight_of_features_text">
                  <p className="heading_div_features">
                    Local stores up to 50 miles
                  </p>
                  <p className="text_div_features">
                    Shop your local stores up to 50 miles
                  </p>
                  <p className="text_div_features">from your location</p>
                </div>
              </div>
            </div>
            {/* point 2 */}
            <div className="display_flex_div">
              <div className="iconmadebyghias">
                {" "}
                <img className="secure_icon" src={secure} alt={"secureicon"} />
              </div>
              <div className="text_ofFeaturs">
                <div className="lineHeight_of_features_text">
                  <p className="heading_div_features">
                    Save your favorite items
                  </p>
                  <p className="text_div_features">
                    Save your favorite items or simply
                  </p>
                  <p className="text_div_features">reorder from past orders</p>
                </div>
              </div>
            </div>
            {/* point 3 */}
            <div className="display_flex_div">
              <div className="iconmadebyghias">
                {" "}
                <img className="secure_icon" src={secure} alt={"secureicon"} />
              </div>
              <div className="text_ofFeaturs">
                <div className="lineHeight_of_features_text">
                  <p className="heading_div_features">Secure checkout</p>
                  <p className="text_div_features">
                    Secure checkout with all major credit
                  </p>
                  <p className="text_div_features">
                    cards (Visa, Mastercard, Amex,
                  </p>
                  <p className="text_div_features">Discover) and PayPal</p>
                </div>
              </div>
            </div>
            {/* point 4 */}
            <div className="display_flex_div">
              <div className="iconmadebyghias">
                {" "}
                <img className="secure_icon" src={secure} alt={"secureicon"} />
              </div>
              <div className="text_ofFeaturs">
                <div className="lineHeight_of_features_text">
                  <p className="heading_div_features">
                    Chat with the merchant in real time
                  </p>
                  <p className="text_div_features">
                    Chat with the merchant in real time
                  </p>
                  <p className="text_div_features">
                    about your order (e.g Please cut my
                  </p>
                  <p className="text_div_features">
                    yam to make sure it’s good)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={8}>
          {" "}
          <img className="mobile_image_features" src={imamobile} alt={"moi"} />
        </Col>
        <Col lg={8}>
          {" "}
          <div className="float_right_div22">
            {/* points */}
            <div className="display_flex_div">
              <div className="iconmadebyghias">
                {" "}
                <img className="secure_icon" src={secure} alt={"secureicon"} />
              </div>
              <div className="text_ofFeaturs">
                <div className="lineHeight_of_features_text">
                  <p className="heading_div_features">
                    Shop any time of the day
                  </p>
                  <p className="text_div_features">Shop any time of the</p>
                  <p className="text_div_features">day</p>
                </div>
              </div>
            </div>
            {/* point 2 */}
            <div className="display_flex_div">
              <div className="iconmadebyghias">
                {" "}
                <img className="secure_icon" src={secure} alt={"secureicon"} />
              </div>
              <div className="text_ofFeaturs">
                <div className="lineHeight_of_features_text">
                  <p className="heading_div_features">Over 3500 items</p>
                  <p className="text_div_features">
                    Over 3500 items from different
                  </p>
                  <p className="text_div_features">stores nationwide</p>
                </div>
              </div>
            </div>
            {/* point 3 */}
            <div className="display_flex_div">
              <div className="iconmadebyghias">
                {" "}
                <img className="secure_icon" src={secure} alt={"secureicon"} />
              </div>
              <div className="text_ofFeaturs">
                <div className="lineHeight_of_features_text">
                  <p className="heading_div_features">
                    Responsive search function
                  </p>
                  <p className="text_div_features">
                    An intuitive and responsive search
                  </p>
                  <p className="text_div_features">
                    function to enhance your shopping
                  </p>
                  <p className="text_div_features">experience</p>
                </div>
              </div>
            </div>
            {/* point 4 */}
            <div className="display_flex_div">
              <div className="iconmadebyghias">
                {" "}
                <img className="secure_icon" src={secure} alt={"secureicon"} />
              </div>
              <div className="text_ofFeaturs">
                <div className="lineHeight_of_features_text">
                  <p className="heading_div_features">
                    Responsive search function
                  </p>
                  <p className="text_div_features">
                    An intuitive and responsive search
                  </p>
                  <p className="text_div_features">
                    function to enhance your shopping
                  </p>
                  <p className="text_div_features">experience</p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Features;
